<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="!emailSent" class="display-1"
              >Reset your password</v-toolbar-title
            >
            <v-toolbar-title v-if="emailSent" class="display-1"
              >Help is on the way!</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-form v-if="!emailSent" @submit.prevent="sendResetRequest">
              <v-text-field
                autofocus
                v-model="email"
                label="Enter your email address and we'll send you a link to reset your password."
                prepend-icon="mdi-email-outline"
                @blur="$v.email.$touch()"
              />
              <div class="red--text text--lighten-1" v-if="$v.email.$error">
                <div v-if="!$v.email.email">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Invalid email address
                </div>
              </div>

              <v-btn
                type="submit"
                color="success"
                name="button"
                :disabled="$v.$invalid"
              >
                Submit
              </v-btn>
            </v-form>

            <div v-if="emailSent">
              An email with a link has been sent to that address
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import api from "@/services/api.js";

export default {
  name: "ForgotPassword",

  data() {
    return {
      email: "",
      emailSent: false,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    sendResetRequest() {
      //console.log("Entering sendResetRequest method");
      api.sendPasswordResetRequest({ username: this.email }).then(() => {
        this.emailSent = true;
      });
    },
  },
};
</script>

<style></style>
